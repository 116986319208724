import "./scss/index.scss";

import Carousel, { Modal, ModalGateway } from "react-images";
import React, { useCallback, useEffect, useState } from "react";

import Gallery from "react-photo-gallery";
import Loading from "./components/ui/Loading";
import axios from "axios";
import { useLocation } from "react-router-dom";

const App = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  useEffect(() => {
    let mounted = true;
    const fetchPhotos = async () => {
      try {
        const result = await axios.get(`/api/`);
        setLoading(false);
        setPhotos(result.data);
      } catch (err) {
        console.error(err);
      }
    };
    if (mounted) {
      fetchPhotos();
    }
    return () => (mounted = false);
  }, [token]);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  return (
    <div className="gallery-wrap" id="galleryPage">
      {loading ? (
        <div className="loading">
          <Loading />
        </div>
      ) : (
        <>
          <Gallery photos={photos} onClick={openLightbox} />

          <ModalGateway>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photos.map((x) => ({
                    ...x,
                    srcset: x.srcSet,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGateway>
          <footer className="copyright">
            Fan site. All content &copy;2022 Henry Winkler. Admin:{" "}
            <a href="mailto: kross@k1r.com">kross@k1r.com</a>
          </footer>
        </>
      )}
    </div>
  );
};

export default App;
