import Loader from "react-loader-spinner";
import PropTypes from "prop-types";
import React from "react";

const Loading = ({ h = 300, w = 300, t = "Puff" }) => {
  return <Loader type={t} color="#8BC1FE" height={h} width={w} timeout={0} />;
};
Loading.propTypes = {
  h: PropTypes.number,
  w: PropTypes.number,
  t: PropTypes.string,
};
export default Loading;

// Audio
// Ball-Triangle
// Bars
// Circles
// Grid
// Hearts
// Oval
// Puff
// Rings
// TailSpin
// ThreeDots

// visible
// type
// height
// width
// color
// secondaryColor
// timeout
// radius
